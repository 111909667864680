import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AuthContextProvider from './shared/context/auth-context';
import { ConfigProvider } from 'antd';
import elGR from 'antd/locale/el_GR';
import { config } from './shared/styles/theme/config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import 'dayjs/locale/el';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
dayjs.locale('el');
root.render(
  <QueryClientProvider client={queryClient}>
    <AuthContextProvider>
      <ConfigProvider theme={config} locale={elGR}>
        <App />
      </ConfigProvider>
    </AuthContextProvider>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
