import { Col, Row, Card, Typography, List, Flex, Divider } from 'antd';
import {
  baseColors,
  baseStyle,
  cardStyle,
} from '../../shared/styles/baseStyle';
import { FC } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import {
  actionCardCol,
  subscriptionCardBody,
  subscriptionCardHeader,
  titleCardCol,
} from './style';

const Subscriptions: FC = () => {
  const { Title, Text } = Typography;
  const basicTitle = 'Basic';
  const premiumTitle = 'Premium';
  const basicFooter = 'ΑΠΟΚΛΕΙΣΤΙΚΑ ΜΕΣΩ ΟΠΕΚΕΠΕ';
  const premiumFooter = 'ΙΔΑΝΙΚΗ ΓΙΑ ΑΓΡΟΤΕΣ ΚΑΙ ΓΕΩΠΟΝΟΥΣ';
  const basicheaderTitle = 'Εφαρμογή ΟΠΕΚΕΠΕ';
  const premiumHeaderTitle = 'SMART FARMING';
  const featuresBasic = [
    'ΚΑΤΑΓΡΑΦΗ ΑΓΡΟΤΕΜΑΧΙΩΝ',
    'ΗΜΕΡΟΛΟΓΙΟ ΕΡΓΑΣΙΩΝ ΑΓΡΟΥ',
    'ΣΥΜΒΟΥΛΕΣ ΘΡΕΨΗΣ & ΠΛΗΡΕΣ ΙΣΟΖΥΓΙΟ ΘΡΕΠΤΙΚΩΝ ΟΥΣΙΩΝ',
    'ΥΠΟΛΟΓΙΣΜΟΣ ΕΦΑΡΜΟΓΗΣ ΛΙΠΑΣΜΑΤΩΝ ΒΑΣΕΙ ΕΔΑΦΟΛΟΓΙΚΩΝ ΑΝΑΛΥΣΕΩΝ',
    'ΕΝΔΕΙΞΕΙΣ ΟΣΩΝ ΑΦΟΡΑ ΤΑ ΝΟΜΙΜΑ ΟΡΙΑ ΚΑΙ ΤΙΣ ΑΠΑΙΤΗΣΕΙΣ ΣΕ ΘΡΕΠΤΙΚΑ ΣΥΣΤΑΤΙΚΑ, ΦΥΤΟΠΡΟΣΤΑΤΕΥΤΙΚΑ ΚΑΙ ΝΕΡΟ',
    'ΕΚΤΙΜΗΣΗ ΑΝΘΡΑΚΙΚΟΥ ΑΠΟΤΥΠΩΜΑΤΟΣ',
    'ΚΑΤΑΝΑΛΩΣΗ ΥΔΑΤΟΣ',
    'ΠΡΟΣΒΑΣΗ ΣΕ ΟΛΟ ΤΟ FARMABASE',
    'ALERT & ΕΙΔΟΠΟΙΗΣΕΙΣ',
  ];

  const featuresPremium = [
    'ΚΑΤΑΓΡΑΦΗ ΑΓΡΟΤΕΜΑΧΙΩΝ',
    'ΗΜΕΡΟΛΟΓΙΟ ΕΡΓΑΣΙΩΝ ΑΓΡΟΥ',
    'ΠΛΗΡΕΣ ΙΣΟΖΥΓΙΟ ΘΡΕΠΤΙΚΩΝ ΟΥΣΙΩΝ',
    'ΥΠΟΛΟΓΙΣΜΟΣ ΕΦΑΡΜΟΓΗΣ ΛΙΠΑΣΜΑΤΩΝ ΒΑΣΕΙ ΕΔΑΦΟΛΟΓΙΚΩΝ ΑΝΑΛΥΣΕΩΝ',
    'ΕΝΔΕΙΞΕΙΣ ΟΣΩΝ ΑΦΟΡΑ ΤΑ ΝΟΜΙΜΑ ΟΡΙΑ ΚΑΙ ΤΙΣ ΑΠΑΙΤΗΣΕΙΣ ΣΕ ΘΡΕΠΤΙΚΑ ΣΥΣΤΑΤΙΚΑ, ΦΥΤΟΠΡΟΣΤΑΤΕΥΤΙΚΑ ΚΑΙ ΝΕΡΟ',
    'ΚΑΤΑΝΑΛΩΣΗ ΥΔΑΤΟΣ',
    'ΕΚΤΙΜΗΣΗ ΑΝΘΡΑΚΙΚΟΥ ΑΠΟΤΥΠΩΜΑΤΟΣ',
    'ΕΦΑΡΜΟΓΗ ΑΡΔΕΥΣΕΩΝ',
    'ΠΛΗΡΗ ΠΡΟΣΒΑΣΗ ΣΤΗ FARMACON:FARMABASE | FARMALEARN | FARMATOOLS',
    'ΠΡΟΓΝΩΣΗ ΚΑΙΡΟΥ ΜΕ ΒΕΛΤΙΣΤΟΥΣ ΧΡΟΝΟΥΣ ΨΕΚΑΣΜΟΥ (48 ΩΡΩΝ)',
    'ALERT & ΕΙΔΟΠΟΙΗΣΕΙΣ',
  ];

  const subscriptions = [
    {
      id: '1',
      price: '49.99 €',
      headerTitle: basicheaderTitle,
      title: basicTitle,
      listData: featuresBasic,
      footer: basicFooter,
    },
    {
      id: '2',
      headerTitle: premiumHeaderTitle,
      price: '349.99 €',
      title: premiumTitle,
      listData: featuresPremium,
      footer: premiumFooter,
    },
  ];

  return (
    <Flex gap="small" vertical>
      <Title level={2} style={{ textAlign: 'center' }}>
        Συνδρομές
      </Title>
      <Row gutter={[48, 8]} justify="center" style={{ ...baseStyle.mainRow }}>
        {subscriptions.map((subscription) => (
          <Col key={subscription.id} xs={22} md={22} xl={9}>
            <Card
              styles={{
                header: { ...subscriptionCardHeader, textAlign: 'center' },
                body: {
                  ...cardStyle,
                  ...subscriptionCardBody,
                },
                actions: { backgroundColor: baseColors.fsBlue },
              }}
              style={{
                backgroundColor: 'transparent',
              }}
              actions={[
                <Row key={1} justify="center">
                  <Col
                    span={12}
                    style={{ ...actionCardCol, textAlign: 'center' }}
                  >
                    <Text
                      strong
                      style={{
                        fontSize: 16,
                        color: baseColors.fsLightBlue,
                      }}
                    >
                      {subscription.footer}
                    </Text>
                  </Col>
                </Row>,
              ]}
              title={
                <Row justify="center" align="middle">
                  <Col
                    span={16}
                    style={{
                      ...titleCardCol,
                      textAlign: 'center',
                    }}
                  >
                    {subscription.headerTitle}
                  </Col>
                </Row>
              }
            >
              <Row justify="center">
                <Text
                  strong
                  style={{
                    color: baseColors.fsLightBlue,
                    fontSize: 30,
                  }}
                >
                  AGROS
                </Text>
              </Row>
              <Row justify="center">
                <Text
                  strong
                  style={{
                    color: baseColors.fsLightBlue,
                    fontSize: 20,
                  }}
                >
                  {subscription.title}
                </Text>
              </Row>
              <Row justify="center">
                <Col span={6}>
                  <Divider
                    style={{
                      backgroundColor: baseColors.fsLightBlue,
                      margin: 4,
                    }}
                  />
                </Col>
              </Row>
              <Row justify="center">
                <Text
                  style={{
                    color: baseColors.fsLightBlue,
                    fontSize: 35,
                  }}
                >
                  {subscription.price}
                </Text>
                <Text
                  strong
                  style={{
                    color: baseColors.fsLightBlue,
                    fontSize: 15,
                    marginTop: 20,
                  }}
                >
                  /
                </Text>
                <Text
                  strong
                  style={{
                    color: baseColors.fsLightBlue,
                    fontSize: 15,
                    marginTop: 22,
                  }}
                >
                  {' '}
                  το έτος
                </Text>
              </Row>
              <List
                style={{ padding: 3, margin: 25 }}
                size="small"
                split={false}
                dataSource={subscription.listData}
                renderItem={(subscription) => (
                  <List.Item style={{ padding: 3 }}>
                    <List.Item.Meta
                      avatar={
                        <CheckOutlined
                          style={{
                            color: baseColors.fsLightBlue,
                            marginRight: '5px',
                          }}
                        />
                      }
                      title={
                        <Text
                          strong
                          style={{
                            color: baseColors.fsLightBlue,
                            fontSize: 13,
                          }}
                        >
                          {subscription}
                        </Text>
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </Flex>
  );
};

export default Subscriptions;
