import { baseColors } from '../../shared/styles/baseStyle';

export const subscriptionCardHeader = {
  color: baseColors.fsBlue,
  minHeight: '30px',
};
export const subscriptionCardBody = {
  backgroundColor: baseColors.fsGreyBlue,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderTopRightRadius: 12,
  borderTopLeftRadius: 12,
  padding: 5,
};

export const actionCardCol = {
  width: '100%',
  fontSize: 18,
  backgroundColor: baseColors.fsBlue,
};
export const titleCardCol = {
  width: '100%',
  fontSize: 18,
  backgroundColor: baseColors.fsLightBlue,
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
};
