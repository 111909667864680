import { FC, useContext } from 'react';
import { Col, Dropdown, Layout, MenuProps, Row, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { AuthContext } from '../../shared/context/auth-context';
import { useNavigate } from 'react-router-dom';

const Header: FC = () => {
  const navigate = useNavigate();
  const { Header } = Layout;
  const { logout, firstName, lastName } = useContext(AuthContext);

  const items: MenuProps['items'] = [
    {
      label: 'Συνδρομές',
      key: '/subscriptions',
    },
    {
      label: 'Αλλαγή στοιχείων πρόσβασης',
      key: '/profile',
    },
    { type: 'divider' },
    {
      danger: true,
      label: 'Αποσύνδεση',
      key: '/logout',
    },
  ];
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '/logout') {
      logout();
      return;
    }
    navigate(e.key);
  };

  return (
    <Header>
      <Row justify="center">
        <Col
          sm={{ span: 4 }}
          xs={{ span: 4, offset: 10 }}
          md={{ span: 4, offset: 14 }}
          lg={{ span: 4, offset: 14 }}
          xl={{ span: 4, offset: 16 }}
        >
          <Space>
            <Dropdown.Button
              icon={<UserOutlined />}
              size="large"
              type="primary"
              menu={{
                items: items,
                onClick: handleMenuClick,
              }}
              trigger={['click']}
            >
              {firstName} {lastName}
            </Dropdown.Button>
          </Space>
        </Col>
      </Row>
    </Header>
  );
};
export default Header;
