import { FC } from 'react';
import { Layout } from 'antd';
type Props = {
  children: any;
};
const Content: FC<Props> = ({ children }) => {
  const { Content } = Layout;
  return <Content style={{ padding: '8px 24px' }}>{children} </Content>;
};
export default Content;
