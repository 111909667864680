import { Navigate, createBrowserRouter } from 'react-router-dom';
import Home from '../../pages/Home';
import AddUser from '../../pages/Users/AddUser';
import UsersCatalogue from '../../pages/Users/UsersCatalogue';
import AddCultivation from '../../pages/Cultivations/AddCultivation';
import CultivationsCatalogue from '../../pages/Cultivations/CultivationCatalogue';
import Calendar from '../../pages/Calendar/Calendar';
import Login from '../../pages/Login/Login';
import Mainlayout from '../MainLayout';
import PrivateRoute from './PrivateRoute';
import Register from '../../pages/Register/Register';
import GlobalRoute from './GlobalRouter';
import PrivateForRole from './PrivateForRole';
import ResetPassword from '../../pages/Profile/ResetPassword';
import ForgotPassword from '../../pages/ForgotPassword/ForgotPassword';
import endPoints from '../../shared/endPoints.json';
import { Col, Row } from 'antd';
import { baseStyle } from '../../shared/styles/baseStyle';
import Subscriptions from '../../pages/ Subscriptions/ Subscriptions';

export const router = createBrowserRouter([
  {
    path: '/login',
    element: <GlobalRoute />,
    children: [{ path: '', element: <Login /> }],
  },
  {
    path: '/register',
    element: <GlobalRoute />,
    children: [{ path: '', element: <Register /> }],
  },
  {
    path: '/activatePassword',
    element: <GlobalRoute />,
    children: [
      {
        path: '',
        element: (
          <ResetPassword
            title={'Αλλαγή Προσωρινού Κωδικού'}
            endPoint={endPoints.USER.ACTIVATETEMPPASSWORD}
          />
        ),
      },
    ],
  },

  {
    path: '/forgotPassword',
    element: <GlobalRoute />,
    children: [{ path: '', element: <ForgotPassword /> }],
  },
  {
    path: '/resetPassword',
    element: <GlobalRoute />,
    children: [
      {
        path: '',
        element: (
          <ResetPassword
            title={'Επαναφορά κωδικού πρόσβασης'}
            endPoint={endPoints.USER.RESETPASSWORDWITHTOKEN}
          />
        ),
      },
    ],
  },
  {
    path: '/',
    element: <PrivateRoute />,
    children: [
      {
        path: '/',
        element: <Mainlayout />,
        children: [
          { path: '', element: <Home /> },
          {
            path: 'users/catalogue',
            element: <PrivateForRole />,
            children: [{ path: '', element: <UsersCatalogue /> }],
          },
          {
            path: 'users/add',
            element: <PrivateForRole />,
            children: [
              {
                path: '',
                element: (
                  <Row justify="center" style={baseStyle.mainRow}>
                    <Col span={24}>
                      <AddUser
                        showFieldOnFilter={true}
                        title={'Δημιουργία Χρήστη'}
                        button={'Αποθήκευση'}
                        onFinishFunction={undefined}
                        viewOnly={false}
                        selectedUserDetails={undefined}
                        apiMethod={'post'}
                        successTitle={'Ο χρήστης δημιουργήθηκε με επιτυχία!'}
                        setIsEditCatalogueModalOpen={() => {
                          // intentionally empty
                        }}
                        endPoint={endPoints.USER.REGISTERADMIN}
                      />
                    </Col>
                  </Row>
                ),
              },
            ],
          },
          {
            path: 'cultivations/add',
            element: <AddCultivation />,
          },
          {
            path: 'cultivations/catalogue',
            element: <CultivationsCatalogue />,
          },
          {
            path: 'calendar',
            element: <Calendar />,
          },
          {
            path: 'profile',
            element: (
              <ResetPassword
                title={'Αλλαγή Στοιχείων Πρόσβασης'}
                endPoint={endPoints.USER.RESETPASSWORD}
              />
            ),
          },
          {
            path: 'subscriptions',
            element: <Subscriptions />,
          },
        ],
      },
      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
]);
