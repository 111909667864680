import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  InputNumber,
  DatePicker,
  FormProps,
  message,
} from 'antd';
import { FC, useEffect, useState, useContext } from 'react';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import {
  baseStyle,
  baseFormLayout,
  fullWidth,
  cardStyle,
} from '../../../shared/styles/baseStyle';
import {
  SoilType,
  User,
  NamedItem,
  CultivationDetails,
  CultivationFormValues,
  FarmerOptions,
  Cultivation,
} from '../../../types/types';
import { Method } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import useCreateAxiosInstance from '../../../shared/hooks/useCreateAxiosInstance';
import { getData, mutateData } from '../../../shared/services/apiServices';
import endPoints from '../../../shared/endPoints.json';
import MapView from '../../MapView/MapView';
import { AuthContext } from '../../../shared/context/auth-context';

type Props = {
  selectedCultivationDetails: CultivationDetails | null;
  apiMethod: Method;
  successTitle?: string;
  setIsEditCatalogueModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  viewOnly: boolean;
};
const CultivationForm: FC<Props> = ({
  selectedCultivationDetails,
  apiMethod,
  successTitle,
  setIsEditCatalogueModalOpen,
  viewOnly,
}) => {
  const { role, userId: userIdFromToken } = useContext(AuthContext);
  const requiredRuleConfig = {
    required: true,
    message: 'Υποχρεωτικό πεδίο',
  };
  const coordinatesRegex = new RegExp(
    /\[\[(-?\d+\.\d+),\s*(-?\d+\.\d+)\](,\s*\[-?\d+\.\d+,\s*-?\d+\.\d+\])*\]/,
  );
  const coordinatesRuleConfig = {
    pattern: coordinatesRegex,
    message:
      'Οι συντεταγμένες πρέπει να είναι της μορφής [[γεωγ/κό πλάτος,γεωγ/κό μήκος],[...,...],...]',
  };

  const defaultParams = '&Role=Καλλιεργητής&Items=1000&Page=1';
  const dateFormat = 'DD/MM/YYYY';
  const [form] = Form.useForm();
  const [clearCoordinates, setClearCoordinates] = useState(false);
  const [coords, setCoords] = useState(selectedCultivationDetails?.coordinates);
  const { request } = useCreateAxiosInstance();
  const [messageApi, contextHolder] = message.useMessage();
  const [landParcelMutation, setLandParcelMutation] = useState(false);
  const [farmerOptions, setFarmerOptions] = useState<FarmerOptions[]>([]);
  const [userId, setUserId] = useState(selectedCultivationDetails?.userId);
  const [cultivationId, setCultivationId] = useState(
    selectedCultivationDetails?.cultivationId,
  );
  const [waterSourceId, setWaterSourceId] = useState(
    selectedCultivationDetails?.waterSourceId,
  );
  const [soilTypeId, setSoilTypeId] = useState(
    selectedCultivationDetails?.soilTypeId,
  );
  const [irrigationSystemId, setIrrigationSystemId] = useState(
    selectedCultivationDetails?.irrigationSystemId,
  );
  const [cultivationPhaseId, setCultivationPhaseId] = useState(
    selectedCultivationDetails?.cultivationPhaseId,
  );

  const [municipalityId, setMunicipalityId] = useState(
    selectedCultivationDetails?.municipalityId,
  );
  const [ownershipStatusId, setOwnershipStatusId] = useState(
    selectedCultivationDetails?.ownershipStatusId,
  );
  const [farmerSearchValue, setFarmerSearchValue] = useState('');
  const [polygon, setPolygon] = useState<any>(
    selectedCultivationDetails?.coordinates
      ? JSON.parse(selectedCultivationDetails.coordinates)
      : null,
  );
  const { data: waterSourceItems, isLoading: waterSourceItemsLoading } =
    useQuery({
      queryKey: ['waterSource'],
      queryFn: () => getData(request, endPoints.CULTIVATION.WATERSOURCE),
      select(data) {
        return data.data.map((item: NamedItem) => ({
          value: item.id,
          label: item.name,
        }));
      },
      enabled: !viewOnly,
    });
  const { data: soilTypeItems, isLoading: soilTypeItemsLoading } = useQuery({
    queryKey: ['soilType'],
    queryFn: () => getData(request, endPoints.CULTIVATION.SOILTYPE),
    select(data) {
      return data.data;
    },
    enabled: !!waterSourceItems && !viewOnly,
  });
  const { data: municipalityItems, isLoading: municipalityItemsLoading } =
    useQuery({
      queryKey: ['municipality'],
      queryFn: () => getData(request, endPoints.CULTIVATION.MUNICIPALITY),
      select(data) {
        return data.data.map((region: any) => {
          return {
            label: region.region,
            options: region.municipalities.map((municipality: any) => {
              return {
                value: municipality.id,
                label: municipality.name,
                region: region.region,
              };
            }),
          };
        });
      },
      enabled: !!waterSourceItems && !viewOnly,
    });
  const {
    data: cultivationPhaseItems,
    isLoading: cultivationPhaseItemsLoading,
  } = useQuery({
    queryKey: ['cultivationPhase'],
    queryFn: () => getData(request, endPoints.CULTIVATION.CULTIVATIONPHASE),
    select(data) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
    enabled: !!waterSourceItems && !viewOnly,
  });
  const { data: cultivationItems, isLoading: cultivationItemsLoading } =
    useQuery({
      queryKey: ['cultivation'],
      queryFn: () => getData(request, endPoints.CULTIVATION.CULTIVATION),
      select(data) {
        return data.data.map((item: Cultivation) => ({
          value: item.id,
          label: item.nameGr,
        }));
      },
      enabled: !!waterSourceItems && !viewOnly,
    });
  const {
    data: irrigationSystemItems,
    isLoading: irrigationSystemItemsLoading,
  } = useQuery({
    queryKey: ['irrigationSystem'],
    queryFn: () => getData(request, endPoints.CULTIVATION.IRRIGATIONSYSTEM),
    select(data) {
      return data.data.map((item: NamedItem) => ({
        value: item.id,
        label: item.name,
      }));
    },
    enabled: !!waterSourceItems && !viewOnly,
  });
  const { data: ownershipStatusItems, isLoading: ownershipStatusLoading } =
    useQuery({
      queryKey: ['ownershipStatus'],
      queryFn: () => getData(request, endPoints.CULTIVATION.OWNERSHIPSTATUS),
      select(data) {
        return data.data.map((item: NamedItem) => ({
          value: item.id,
          label: item.name,
        }));
      },
      enabled: !!waterSourceItems && !viewOnly,
    });
  const { data: farmers, isSuccess } = useQuery({
    queryKey: ['farmers', farmerSearchValue],
    queryFn: () =>
      getData(
        request,
        `${endPoints.USER.USER}?fullName=${farmerSearchValue}${defaultParams}`,
      ),
    select(data) {
      return data.data.items.map((item: User) => ({
        value: item.firstName + ' ' + item.lastName,
        label: item.firstName + ' ' + item.lastName,
        key: item.id,
      }));
    },
    enabled: farmerSearchValue.length >= 3,
  });
  useEffect(() => {
    if (isSuccess) {
      setFarmerOptions(farmers);
    }
  }, [farmers]);
  useEffect(() => {
    form.setFieldsValue({
      coordinates: polygon && JSON.stringify(polygon),
    });
  }, [polygon]);
  useEffect(() => {
    form.setFieldsValue({
      userId: selectedCultivationDetails?.user,
      from: selectedCultivationDetails?.from,
      name: selectedCultivationDetails?.name,
      cultivationId: selectedCultivationDetails?.cultivation,
      plantingDistance: selectedCultivationDetails?.plantingDistance,
      plantingDate: selectedCultivationDetails?.plantingDate,
      to: selectedCultivationDetails?.to,
      harvestingDate: selectedCultivationDetails?.harvestingDate,
      waterSourceId: selectedCultivationDetails?.waterSource,
      soilTypeId: selectedCultivationDetails?.soilType,
      placeName: selectedCultivationDetails?.placeName,
      variety: selectedCultivationDetails?.variety,
      irrigationSystemId: selectedCultivationDetails?.irrigationSystem,
      cultivationPhaseId: selectedCultivationDetails?.cultivationPhase,
      waterCapacityPoint: selectedCultivationDetails?.waterCapacityPoint,
      permanentWitheringPoint:
        selectedCultivationDetails?.permanentWitheringPoint,
      maximumAllowedDepletion:
        selectedCultivationDetails?.maximumAllowedDepletion,
      district: selectedCultivationDetails?.district,
      area: selectedCultivationDetails?.area,
      municipalityId: selectedCultivationDetails?.municipality,
      ownershipStatusId: selectedCultivationDetails?.ownershipStatus,
      region: selectedCultivationDetails?.region,
    });
  }, [selectedCultivationDetails]);

  const { mutate } = useMutation({
    mutationFn: (formValues: any) =>
      mutateData(
        request,
        endPoints.CULTIVATION.LANDPARCEL,
        formValues,
        apiMethod,
      ),
    onSuccess() {
      messageApi.success(successTitle);
      setIsEditCatalogueModalOpen(false);
      form.resetFields();
      setLandParcelMutation(!landParcelMutation);
    },
  });

  const onFinish: FormProps['onFinish'] = async (
    values: CultivationFormValues,
  ) => {
    const newValues: CultivationFormValues = {
      ...values,
      from: values.from?.$d.getTime(),
      harvestingDate: values.harvestingDate?.$d.getTime(),
      plantingDate: values.plantingDate?.$d.getTime(),
      to: values.to?.$d.getTime(),
      userId: userId ?? Number(userIdFromToken),
      cultivationId: cultivationId,
      waterSourceId: waterSourceId,
      soilTypeId: soilTypeId,
      irrigationSystemId: irrigationSystemId,
      cultivationPhaseId: cultivationPhaseId,
      id: selectedCultivationDetails?.id,
      ownershipStatusId: ownershipStatusId,
      municipalityId: municipalityId,
      coordinates: values.coordinates || undefined,
    };
    Object.keys(newValues).forEach((key) => {
      const typedKey = key as keyof CultivationFormValues;
      if (newValues[typedKey] === undefined) {
        newValues[typedKey] = null;
      }
    });
    mutate(newValues);
  };
  const onChangeSoilType = (value: number) => {
    if (!value) {
      form.resetFields([
        'waterCapacityPoint',
        'permanentWitheringPoint',
        'maximumAllowedDepletion',
      ]);
      return;
    }
    const selectedSoil = soilTypeItems?.find(
      (soil: SoilType) => soil.id === value,
    );
    if (selectedSoil) {
      form.setFieldsValue({
        waterCapacityPoint: selectedSoil.fc,
        permanentWitheringPoint: selectedSoil.pwp,
        maximumAllowedDepletion: selectedSoil.mad,
      });
    }
  };
  const validateCoordinates = (value: any) => {
    if (coordinatesRegex.test(value)) {
      return value;
    } else {
      return undefined;
    }
  };
  const onSearch = (value: string) => {
    setFarmerSearchValue(value);
    if (!value) {
      setFarmerOptions([]);
    }
  };

  return (
    <>
      {contextHolder}
      <Row justify="center" style={baseStyle.mainRow}>
        <Col span={24}>
          <Card
            title={!viewOnly && 'Πληροφορίες καλλιέργειας'}
            style={cardStyle}
            loading={
              waterSourceItemsLoading ||
              soilTypeItemsLoading ||
              cultivationPhaseItemsLoading ||
              irrigationSystemItemsLoading ||
              cultivationItemsLoading
            }
          >
            <Row>
              <Col span={24}>
                <MapView
                  path={undefined}
                  height={'75vh'}
                  coordinates={coords && [JSON.parse(coords)]}
                  setPolygon={setPolygon}
                  showDrawControls={!viewOnly}
                  landParcelMutation={landParcelMutation}
                  clearCoordinates={clearCoordinates}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <Form
                  {...baseFormLayout}
                  name="basic"
                  layout="vertical"
                  size="middle"
                  form={form}
                  onFinish={onFinish}
                >
                  <Row>
                    {role == 'Διαχειριστής' && (
                      <Col xs={12} xl={6}>
                        <Form.Item
                          name="userId"
                          label="Καλλιεργητής"
                          rules={[requiredRuleConfig]}
                        >
                          <Select
                            suffixIcon={null}
                            showSearch
                            allowClear
                            onClear={() => setFarmerOptions([])}
                            notFoundContent={null}
                            placeholder={'Αναζητείστε Καλλιεργητή'}
                            defaultActiveFirstOption={false}
                            onSearch={onSearch}
                            options={farmerOptions}
                            onSelect={(
                              value: string,
                              option: FarmerOptions,
                            ) => {
                              setUserId(option.key);
                            }}
                            disabled={viewOnly}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col xs={12} xl={6}>
                      <Form.Item
                        name="coordinates"
                        label="Συντεταγμένες"
                        rules={[coordinatesRuleConfig]}
                      >
                        <Input
                          placeholder="πχ [[39.53240,21.04093],[...,...],...]"
                          disabled={viewOnly}
                          onPressEnter={(e: any) => {
                            setCoords(validateCoordinates(e.target.value));
                          }}
                          allowClear
                          onClear={() => {
                            setClearCoordinates(!clearCoordinates);
                            setCoords(undefined);
                          }}
                          onBlur={(e: any) => {
                            setCoords(validateCoordinates(e.target.value));
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item
                        name="name"
                        label="Ονομασία"
                        rules={[requiredRuleConfig]}
                      >
                        <Input placeholder="Ονομασία" disabled={viewOnly} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item
                        name="cultivationId"
                        label="Καλλιέργεια"
                        rules={[requiredRuleConfig]}
                      >
                        <Select
                          allowClear
                          options={cultivationItems}
                          placeholder="Επιλέξτε Καλλιέργεια"
                          onSelect={(value: number) => {
                            setCultivationId(value);
                          }}
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item name="placeName" label="Τοπωνύμιο">
                        <Input placeholder="Τοπωνύμιο" disabled={viewOnly} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item
                        name="ownershipStatusId"
                        label="Καθεστός ιδιοκτησίας"
                        rules={[requiredRuleConfig]}
                      >
                        <Select
                          options={ownershipStatusItems}
                          allowClear
                          placeholder="Καθεστός ιδιοκτησίας"
                          onSelect={(value: number) => {
                            setOwnershipStatusId(value);
                          }}
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item name="variety" label="Ποικιλία">
                        <Input placeholder="Ποικιλία" disabled={viewOnly} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item
                        name="area"
                        label="Έκταση (ha)"
                        rules={[requiredRuleConfig]}
                      >
                        <InputNumber
                          type="number"
                          style={fullWidth}
                          step={1}
                          min={0}
                          placeholder="Έκταση (ha)"
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item
                        name="municipalityId"
                        label="Δήμος"
                        rules={[requiredRuleConfig]}
                      >
                        <Select
                          allowClear
                          options={municipalityItems}
                          placeholder="Επιλέξτε Δήμο"
                          onClear={() => form.resetFields(['region'])}
                          onSelect={(value: number, options: any) => {
                            form.setFieldsValue({ region: options.region });
                            setMunicipalityId(value);
                          }}
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item name="region" label="Περιφερειακή ενότητα">
                        <Input placeholder="Περιφερειακή ενότητα" disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item
                        name="district"
                        label="Περιοχή"
                        rules={[requiredRuleConfig]}
                      >
                        <Input placeholder="Περιοχή" disabled={viewOnly} />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item
                        name="plantingDistance"
                        label="Απόσταση Φύτευσης(m)"
                      >
                        <InputNumber
                          type="number"
                          style={fullWidth}
                          step={0.01}
                          min={0}
                          placeholder="Απόσταση Φύτευσης(m)"
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item
                        name="irrigationSystemId"
                        label="Σύστημα Άρδευσης"
                        rules={[requiredRuleConfig]}
                      >
                        <Select
                          allowClear
                          options={irrigationSystemItems}
                          placeholder="Επιλέξτε Σύστημα Άρδευσης"
                          onSelect={(value: number) => {
                            setIrrigationSystemId(value);
                          }}
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item name="waterSourceId" label="Προέλευση Νερού">
                        <Select
                          options={waterSourceItems}
                          allowClear
                          placeholder="Επιλέξτε Προέλευση Νερού"
                          onSelect={(value: number) => {
                            setWaterSourceId(value);
                          }}
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item
                        name="cultivationPhaseId"
                        label="Φάση Καλλιέργειας"
                      >
                        <Select
                          options={cultivationPhaseItems}
                          allowClear
                          placeholder="Επιλέξτε Φάση Καλλιέργειας"
                          onSelect={(value: number) => {
                            setCultivationPhaseId(value);
                          }}
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item name="soilTypeId" label="Τύπος Εδάφους">
                        <Select
                          allowClear
                          options={soilTypeItems?.map((item: SoilType) => ({
                            value: item.id,
                            label: item.name,
                          }))}
                          placeholder="Επιλέξτε Τύπο Εδάφους"
                          onChange={onChangeSoilType}
                          onSelect={(value: number) => {
                            setSoilTypeId(value);
                          }}
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item
                        name="permanentWitheringPoint"
                        label="Σημείο Μόνιμου Μαρασμού"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item
                        name="waterCapacityPoint"
                        label="Σημείο Υδατοϊκανότητας"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={8} xl={6}>
                      <Form.Item
                        name="maximumAllowedDepletion"
                        label="Mέγιστη Eπιτρεπόμενη Aποστράγγιση"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} lg={6}>
                      <Form.Item
                        name="from"
                        label="Από"
                        rules={[requiredRuleConfig]}
                      >
                        <DatePicker
                          format={dateFormat}
                          placeholder="Από"
                          style={{ width: '100%' }}
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={6}>
                      <Form.Item name="to" label="Έως">
                        <DatePicker
                          format={dateFormat}
                          placeholder="Έως"
                          style={{ width: '100%' }}
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={6}>
                      <Form.Item
                        name="plantingDate"
                        label="Ημ/νία Φύτευσης"
                        rules={[requiredRuleConfig]}
                      >
                        <DatePicker
                          format={dateFormat}
                          placeholder="Ημ/νία Φύτευσης"
                          style={{ width: '100%' }}
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} lg={6}>
                      <Form.Item
                        name="harvestingDate"
                        label="Ημ/νία Συγκομιδής"
                      >
                        <DatePicker
                          format={dateFormat}
                          placeholder="Ημ/νία Συγκομιδής"
                          style={{ width: '100%' }}
                          disabled={viewOnly}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {!viewOnly && (
                    <Row justify="center">
                      <Button htmlType="submit" type="primary">
                        Αποθήκευση
                      </Button>
                    </Row>
                  )}
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CultivationForm;
