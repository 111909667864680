import { FC } from 'react';
import { Row, Col, Card, Calendar } from 'antd';
import { useQuery } from '@tanstack/react-query';
import MapView from '../components/MapView/MapView';
import { cardStyle } from '../shared/styles/baseStyle';
import useCreateAxiosInstance from '../shared/hooks/useCreateAxiosInstance';
import { getData } from '../shared/services/apiServices';
import endPoints from '../shared/endPoints.json';
const Home: FC = () => {
  const { request } = useCreateAxiosInstance();
  const { data: cultivations, isLoading } = useQuery({
    queryKey: ['cultivations'],
    queryFn: () =>
      getData(
        request,
        `${endPoints.CULTIVATION.LANDPARCEL}?items=10000000&page=1`,
      ),
    select(data) {
      return data?.data?.items?.map((item: { coordinates: string }) =>
        JSON.parse(item.coordinates),
      );
    },
  });

  return (
    <Row justify="center" gutter={[16, 16]}>
      <Col span={23}>
        <Card style={cardStyle} loading={isLoading}>
          <MapView
            path="home"
            height={'65vh'}
            setPolygon={undefined}
            showDrawControls={false}
            landParcelMutation={undefined}
            clearCoordinates={undefined}
            coordinates={cultivations}
          />
        </Card>
      </Col>
      <Col xs={23} md={7}>
        <Card style={cardStyle}>
          <Calendar fullscreen={false} />
        </Card>
      </Col>
      <Col xs={23} md={16}>
        <Card style={cardStyle}>
          <iframe
            src="https://www.ventusky.com/?p=38.17;23.39;8&l=temperature-2m&lang=el"
            style={{
              width: '100%',
              height: '80vh',
              border: 'none',
            }}
          ></iframe>
        </Card>
      </Col>
    </Row>
  );
};
export default Home;
