import { Layout, Grid, Image, Row, Divider } from 'antd';
import { FC, useContext, useState, ReactNode, useEffect } from 'react';
import {
  TeamOutlined,
  SnippetsOutlined,
  ScheduleOutlined,
  BugOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import SiderMenu from './SiderMenu';
import { AuthContext } from '../../shared/context/auth-context';
const Sider: FC = () => {
  const { role } = useContext(AuthContext);
  const { useBreakpoint } = Grid;
  const { Sider } = Layout;
  const breakpoints = useBreakpoint();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  if (!role) return <></>;
  type SiderItem = {
    key: string;
    icon: JSX.Element;
    label: ReactNode;
    role: string[];
    children?: {
      key: string;
      label: string;
      role: string[];
    }[];
  };
  const siderItems: SiderItem[] = [
    {
      key: '/users',
      icon: <TeamOutlined />,
      label: 'Χρήστες',
      role: ['Διαχειριστής'],
      children: [
        {
          key: '/users/add',
          label: `Προσθήκη`,
          role: ['Διαχειριστής'],
        },
        {
          key: '/users/catalogue',
          label: `Κατάλογος`,
          role: ['Διαχειριστής'],
        },
      ],
    },
    {
      key: '/cultivations',
      icon: <SnippetsOutlined />,
      label: 'Αγροτεμάχια',
      role: ['Διαχειριστής', 'Καλλιεργητής'],
      children: [
        {
          key: '/cultivations/add',
          label: `Προσθήκη`,
          role: ['Διαχειριστής', 'Καλλιεργητής'],
        },
        {
          key: '/cultivations/catalogue',
          label: `Κατάλογος`,
          role: ['Διαχειριστής', 'Καλλιεργητής'],
        },
      ],
    },
    {
      key: '/calendar',
      icon: <ScheduleOutlined />,
      label: 'Ημερολόγιο Αγρού',
      role: ['Διαχειριστής', 'Καλλιεργητής'],
    },
    {
      key: 'pesticides',
      icon: <BugOutlined />,
      label: (
        <div style={{ whiteSpace: 'pre-wrap', lineHeight: '22px' }}>
          Φυτοπροστατευτικά Σκευάσματα
        </div>
      ),
      role: ['Διαχειριστής', 'Καλλιεργητής'],
    },
  ];
  const siderItemsForRole = siderItems
    .map((item) => {
      const filteredChildren = item.children?.filter((child) =>
        child.role.includes(role),
      );
      if (item.role.includes(role)) {
        return {
          ...item,
          children: filteredChildren,
        };
      }
      return null;
    })
    .filter((item) => item !== null);

  const currentPath = location.pathname;
  const openParentKey = siderItemsForRole.find((item) =>
    item?.children?.some((child) => child.key === currentPath),
  )?.key;

  useEffect(() => {
    if (currentPath !== '/' && openParentKey) {
      setOpenKeys([openParentKey]);
    }
  }, [currentPath, openParentKey]);

  const handleOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  return (
    <>
      {!breakpoints.md ? (
        <Sider breakpoint="md" collapsedWidth={0}>
          <SiderMenu
            items={siderItemsForRole}
            selectedKeys={[currentPath]}
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
          />
        </Sider>
      ) : (
        <>
          <Sider
            style={{ borderTopRightRadius: 12, borderBottomLeftRadius: 12 }}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => {
              setCollapsed(value);
            }}
          >
            <Divider />
            <Row
              style={{
                backgroundColor: '#516c8e',
              }}
              justify="center"
            >
              <Image
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#516c8e',
                }}
                onClick={() => {
                  navigate('/');
                  window.location.reload();
                }}
                preview={false}
                src={process.env.PUBLIC_URL + '/logo.png'}
                height="100%"
                width={!collapsed ? 120 : 65}
              />
            </Row>
            <Divider />
            <SiderMenu
              items={siderItemsForRole}
              selectedKeys={[currentPath]}
              openKeys={openKeys}
              onOpenChange={handleOpenChange}
            />
          </Sider>
        </>
      )}
    </>
  );
};
export default Sider;
