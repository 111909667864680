import axios, { AxiosInstance, Method } from 'axios';
import Endpoints from '../../shared/endPoints.json';
import { message } from 'antd';
import { useContext } from 'react';
import { AuthContext } from '../context/auth-context';
import { useNavigate } from 'react-router-dom';

interface RequestParams {
  url: string;
  method: Method;
  data?: any;
  responseType?: any;
}
const useCreateAxiosInstance = (): any => {
  const { token, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const axiosInstance = axios.create({
    baseURL:
      process.env.REACT_APP_ENVIROMENT === 'PRODUCTION'
        ? Endpoints.BASEURL
        : Endpoints.BASEURLQA,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        logout();
        message.error('Παρακαλώ συνδεθείτε ξανά');
        return;
      }
      message.error('Παρουσιάστηκε σφάλμα');
      if (axios.isAxiosError(error)) {
        console.error('Axios Error:', error.message);
      } else {
        console.error('Unexpected Error:', error);
      }
      return Promise.reject(error);
    },
  );
  const request = async ({
    url,
    method,
    data,
    responseType,
  }: RequestParams) => {
    try {
      return await axiosInstance.request({
        url,
        method,
        data,
        responseType,
      });
    } catch (error) {
      console.error('API request error:', error);
      throw error;
    }
  };

  return { request };
};
export default useCreateAxiosInstance;
