export const locale = {
  draw: {
    toolbar: {
      actions: {
        title: 'Ακύρωση σχεδίασης',
        text: 'Άκυρο',
      },
      finish: {
        title: 'Ολοκλήρωση σχεδίασης',
        text: 'Τέλος',
      },
      undo: {
        title: 'Διαγραφή τελευταίου σημείου',
        text: 'Διαγραφή τελευταίου σημείου',
      },
      buttons: {
        polygon: 'Σχεδίαση πολυγώνου',
      },
    },

    handlers: {
      polygon: {
        tooltip: {
          start: 'Κάντε κλικ για να ξεκινήσετε τη σχεδίαση',
          cont: 'Κάντε κλικ για να προσθέσετε σημείο',
          end: 'Κάντε κλικ στο πρώτο σημείο για να ολοκληρώσετε το πολύγωνο',
        },
      },
      polyline: { error: 'Πρόβλημα' },
      rectangle: {
        tooltip: {
          start: 'Κάντε κλικ για να ξεκινήσετε τη σχεδίαση',
          cont: 'Κάντε κλικ για να προσθέσετε σημείο',
          end: 'Κάντε κλικ στο πρώτο σημείο για να ολοκληρώσετε το πολύγωνο',
        },
      },
      simpleshape: {
        tooltip: {
          start: 'Κάντε κλικ για να ξεκινήσετε τη σχεδίαση',
          cont: 'Κάντε κλικ για να προσθέσετε σημείο',
          end: 'Κάντε κλικ στο πρώτο σημείο για να ολοκληρώσετε το πολύγωνο',
        },
      },
      circle: {
        tooltip: {
          start: 'Κάντε κλικ για να ξεκινήσετε τη σχεδίαση',
          cont: 'Κάντε κλικ για να προσθέσετε σημείο',
          end: 'Κάντε κλικ στο πρώτο σημείο για να ολοκληρώσετε το πολύγωνο',
        },
      },
      marker: {
        tooltip: {
          start: 'Κάντε κλικ για να ξεκινήσετε τη σχεδίαση',
          cont: 'Κάντε κλικ για να προσθέσετε σημείο',
          end: 'Κάντε κλικ στο πρώτο σημείο για να ολοκληρώσετε το πολύγωνο',
        },
      },
      circlemarker: {
        tooltip: {
          start: 'Κάντε κλικ για να ξεκινήσετε τη σχεδίαση.',
          cont: 'Κάντε κλικ για να προσθέσετε σημείο',
          end: 'Κάντε κλικ στο πρώτο σημείο για να ολοκληρώσετε το πολύγωνο',
        },
      },
    },
  },
  edit: {
    toolbar: {
      actions: {
        save: {
          title: 'Αποθήκευση αλλαγών',
          text: 'Αποθήκευση',
        },
        cancel: {
          title: 'Ακύρωση επεξεργασίας',
          text: 'Άκυρο',
        },
        clearAll: {
          title: 'Διαγραφή όλων των σχημάτων',
          text: 'Καθαρισμός',
        },
      },
      buttons: {
        edit: 'Επεξεργασία πολυγώνου',
        remove: 'Διαγραφή πολυγώνου',
      },
    },
    handlers: {
      edit: {
        tooltip: {
          text: 'Σύρετε σημεία ή πολύγωνα για να τα επεξεργαστείτε.',
          subtext: 'Κάντε κλικ σε σημείο για διαγραφή',
        },
      },
      remove: {
        tooltip: {
          text: 'Κάντε κλικ σε πολυγώνου για διαγραφή',
        },
      },
    },
  },
};
