/* eslint-disable react/prop-types */
import { useEffect, useMemo, useState } from 'react';
import { RollbackOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { createControlComponent } from '@react-leaflet/core';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import L from 'leaflet';
import {
  MapContainer,
  TileLayer,
  useMap,
  Marker,
  Polygon,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import fieldIcon from '../../assets/field.png';
import DrawTools from './DrawTools';

const FullscreenControl = createControlComponent((props) =>
  L.control.fullscreen({
    titleCancel: 'Exit fullscreen mode',
    content: null,
    forceSeparateButton: true,
    forcePseudoFullscreen: true,
    fullscreenElement: false,
  }),
);
const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};
const customIcon = new L.Icon({
  iconUrl: fieldIcon,
  iconSize: [32, 40],
  iconAnchor: [16, 37],
  popupAnchor: [0, -30],
});

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const MapView = ({
  height,
  coordinates,
  setPolygon,
  showDrawControls,
  landParcelMutation,
  clearCoordinates,
  path,
}) => {
  const [flyTo, setFlyTo] = useState(null);
  const [resetMap, setResetMap] = useState(false);
  const [selectedPolygon, setSelectedPolygon] = useState([]);
  const center = [39.5, 22.0];
  const zoomLevel = 9;
  const maxZoom = 20;
  const minZoom = 2;

  const ComponentResize = () => {
    const map = useMap();
    useEffect(() => {
      if (map) {
        map.invalidateSize();
      }
    }, [map]);

    return null;
  };

  const FlyToPosition = () => {
    const map = useMap();
    useEffect(() => {
      if (map && coordinates && flyTo) {
        map.flyTo(flyTo, 14);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 2500);
      }
    }, [flyTo]);
    return null;
  };

  function calculateBounds(polygons) {
    const bounds = L.latLngBounds();
    polygons?.forEach((polygon) => {
      polygon?.forEach((coord) => {
        bounds?.extend(coord);
      });
    });
    return bounds;
  }

  const calculateZoomLevel = (bounds, map) => {
    const zoom = map.getBoundsZoom(bounds, true);
    return zoom;
  };

  const PolygonCenter = ({ polygons }) => {
    const map = useMap();
    const overallBounds = calculateBounds(polygons);
    const optimalZoom = calculateZoomLevel(overallBounds, map);
    useEffect(() => {
      map.setView(overallBounds.getCenter(), map.getBoundsZoom(overallBounds));
      map.fitBounds(overallBounds, { padding: [70, 70] });
    }, [map, overallBounds, optimalZoom, resetMap]);
    return null;
  };

  const ResetZoom = () => {
    const resetZoom = useMemo(
      () => (
        <Button
          onClick={() => {
            setResetMap(!resetMap);
            setSelectedPolygon([]);
            setFlyTo(null);
          }}
          icon={<RollbackOutlined color="green" />}
          trigger={['click']}
        ></Button>
      ),
      [],
    );

    return (
      <div className={POSITION_CLASSES.bottomright}>
        <div className="leaflet-control leaflet-bar">{resetZoom}</div>
      </div>
    );
  };

  const Markers = () =>
    coordinates?.map((polygon, index) => {
      const center = L.latLngBounds(polygon).getCenter();
      return (
        <Marker
          position={center}
          key={index}
          icon={customIcon}
          eventHandlers={{
            click: () => {
              if (path === 'home') {
                setFlyTo(center);
                setSelectedPolygon(polygon);
              }
            },
          }}
        ></Marker>
      );
    });
  return (
    <MapContainer
      attributionControl={false}
      maxZoom={maxZoom}
      minZoom={minZoom}
      center={center}
      zoom={zoomLevel}
      style={{
        height: height,
      }}
    >
      <FullscreenControl />
      <ComponentResize />
      {coordinates?.length > 0 && <PolygonCenter polygons={coordinates} />}
      <DrawTools
        setPolygon={setPolygon}
        showDrawControls={showDrawControls}
        landParcelMutation={landParcelMutation}
        clearCoordinates={clearCoordinates}
      />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
      />
      {coordinates && <Markers />}
      <ResetZoom />
      {coordinates && <FlyToPosition />}
      {coordinates && path !== 'home' && (
        <Polygon positions={coordinates} color="green"></Polygon>
      )}
      {selectedPolygon && (
        <Polygon positions={selectedPolygon} color="green"></Polygon>
      )}
    </MapContainer>
  );
};

export default MapView;
